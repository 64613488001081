.GraduationPortraits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.GraduationPortraits section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.GraduationPortraits img {
  max-width: 100px;
  cursor: pointer;
  transition: transform 0.2s;
}

.GraduationPortraits img:hover {
  transform: scale(1.1);
}

.lightbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.lightbox:target {
  display: block;
}

.lightbox span {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.lightbox img {
  max-height: 80%;
  max-width: 80%;
  vertical-align: middle;
}

.lightbox-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lightbox-image {
  width: 80vw;    /* Set width to 80% of viewport width */
  height: 80vh;   /* Set height to 80% of viewport height */
  object-fit: contain;  /* Maintain aspect ratio */
}

.lightbox-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 90%;
  margin-top: 1rem;
}

.lightbox-control {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.lightbox-control.prev {
  margin-right: auto;
}

.lightbox-control.next {
  margin-left: auto;
}

.min-h-screen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-grow {
  flex-grow: 1;
}

.sticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

.bg-opacity-90 {
  background-color: rgba(255, 255, 255, 0.9);
}

.border-t {
  border-top-width: 1px;
}

.border-gray-200 {
  border-color: #e5e7eb;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bg-gray-50 {
  background-color: #f9fafb;
}

.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.transition-shadow {
  transition: box-shadow 0.2s;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(255, 0, 0, 0.1);
}

.shadow-xl {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
}

.shadow-2xl {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}

.shadow-3xl {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6);
}